<template>
     <div class="wrapper">
       <!--蒙层-->
       <div class="m_box">
         <img src="https://static.yihu365.cn/img/h5Img/rightTop.jpg" alt="">
       </div>
       <div class="fxbanner">
         <img src="http://m.yihu365.cn/images/fxbanner.png"/>
       </div>
       <div class="wdbg">
         <div class="yun">
           <img src="http://m.yihu365.cn/images/yun.png"/>
         </div>
         <p style="padding:15px 0 10px;font-size:15px;">您好友“<i class="name">{{ nickName ? nickName :(mobile.substr(0,3)+"****"+mobile.substr(7)) }}</i>”邀您加入医护到家</p>
         <div class="fxzcan">
           <input type="button" value="注册用户" class="yh shareBtn" @click="onRegPage()"/>
           <input type="button" value="注册医生护士" class="ys shareBtn2" @click="onRegPage2()"/>
         </div>
         <p>入驻分享专属二维码，邀请好友得奖励！</p>
       </div>
       <div class="wenz">
         <p class="yhjs">医护到家是国内首家护理上门服务平台，是政府批准的全国互联网医养服务试点。通过医护到家，可以随时预约周边护士，提供上门护理服务。</p>
         <ul>
           <li>
             <i class="lsfk"></i>
             <h3>30余项服务，全面满足需求：</h3>
             <p>注射、采血、换药（含PICC）、吸痰、导尿、鼻饲、灌肠、雾化治疗、外科拆线、压疮护理、造口护理、母婴护理、术后护理、康复护理等。</p>
           </li>
           <li>
             <i class="lsfk"></i>
             <h3>30万名医院在职护士：</h3>
             <p>严格的护士准入机制，正规三甲医院护士，国家卫健委认证，随时为您提供上门护理服务。</p>
           </li>
           <li>
             <i class="lsfk"></i>
             <h3>300多个城市，全国范围服务：</h3>
             <p>服务覆盖全国34个省市自治区，为全国用户提供护士到家服务；</p>
           </li>
           <!--<li>
               <i class="lsfk"></i>
               <h3>您的私人专属护士：</h3>
               <p>服务时间、地点、服务项目，甚至护士，您都可以任意选择，我们满足您的护理需求！</p>
           </li>-->
         </ul>
         <div class="nurse_box">
           <div class="n_tit">新客特惠专属商品，购买立减10元-100元不等</div>
          <div class='n_content' v-for="(item,index) in details">
              <img class='n_img' :src="item.HOTPIC ? item.HOTPIC : item.PIC">
              <div class='n_price'>
                <h3>{{item.SERVICE_NAME}}</h3>
                <h4>{{item.PRICE}}元/次</h4>
              </div>
              <button class='n_btn' @click="goToDetails(item)">立即抢购</button>
          </div>
         </div>
       </div>
       <div class="foot" >
         <p>本活动最终解释权归医护到家所有</p>
       </div>

       <van-overlay
           :show="show"
           @click="show = false"
           z-index="100"
       >
         <div class="topBox">
           <img src="https://static.yihu365.cn/img/h5Img/rightTop.jpg" alt="">
         </div>
       </van-overlay>
     </div>
</template>

<script>
import homeApi from '@axios/home';
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import { Toast } from 'vant';
import {setWxShareDate} from '@/libs/utils.js'
import {onMounted, ref, computed} from "vue"

export default {
  name: "registerApp",
  setup() {

    const phone = ref('');
    const userId = ref('');
    const store = useStore();
    const route = useRoute()


     //获取url参数
    const userid = route.query.userid;
    const mobile = route.query.mobile;
    const nickName = route.query.nickName;

    //设置分享
    setWxShareDate('嗨~送你了一份惊喜，注意查收','https://static.yihu365.cn/img/wx-uni/logo_kts.png','','快点击链接，下载后看看是什么~');
    //跳转
    const onRegPage = () =>{
      window.location.href = `http://h5.yihu365.cn/page/recommendRegister.jsp?userid=${userid}&sid=${store.state.sid}&mobile=${mobile}&activityCode=2`
    }
    const onRegPage2 = () =>{
     window.location.href = `http://h5.yihu365.cn/page/recommendRegister.jsp?userid=${userid}&sid=${store.state.sid}&mobile=${mobile}&type=5&activityCode=2`
    }

    /*
    *
    *  *  url参数修改 (不刷新当前页面)
         *  name ： 参数
         *  value ： 参数值
    * */
    const changeURLParam = (name, value) => {
      let url = document.URL, resultUrl = ''
      let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      let r = window.location.search.substr(1).match(reg);
      let replaceText = name + '=' + value;
      if (r != null) {
        let tmp = url.replace(unescape(name + '=' + r[2]), replaceText);
        resultUrl = (tmp);
      } else {
        if (url.match('[\?]')) {
          resultUrl = url + '&' + replaceText;
        }
        else {
          resultUrl = url + '?' + replaceText;
        }
      }
      history.replaceState(null, null, resultUrl)
    }

    const details = ref([

    ])
    const getExtensionServiceInfo = () => {
      let formData = {
        'userid':userid,
        'offset':'0',
        'limit':'20',
        'newversion':'2114'
      }
      homeApi.getExtensionServiceInfo(formData).then((res) => {
           console.log('res',res);
         details.value = res.data;
      })

    }
    getExtensionServiceInfo();

    //跳转
    const  goToDetails = (item) => {
        window.location.href = item.url
    }
    //蒙层
    const show = ref(false)


    //蒙层判断
    if(route.query.activityCode == '2'){
      setTimeout(()=> {
        show.value = true;
      },800)
      setTimeout(()=> {
        show.value = false;
      },3500)
      changeURLParam('activityCode', '100')

    }else{
      show.value = false;
    }

    return {
      userid,
      mobile,
      nickName,
      details,
      show,
      onRegPage,
      onRegPage2,
      goToDetails
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.wrapper{
  background: #e0f7ff;
  padding-bottom: 20px;
}
*{ margin:0; padding:0}
img {border:0;}
body{font-family: "Microsoft YaHei";-webkit-tap-highlight-color:rgba(255,0,0,0);}
ul,ol {list-style:none;}
input {-webkit-appearance: none;}
a{text-decoration: none;color:#404040;}
em,i{ font-style:normal}
h1,h2,h3,h4,h5,h6{ font-weight:normal}
input {-webkit-appearance:none; border: 0;outline:none;}
input[type="submit"],
input[type="reset"],
input[type="button"],
input{-webkit-appearance:none;}
.bag{background: #e0f7ff;}
.fxbanner img{width: 100%;height: auto;}
.wdbg{background: #f93f55;margin: 20px 30px 0 30px;border-radius: 10px;padding:30px;position: relative;}
.fxsr{background: #fff;border-radius: 10px;}
.fxsr li {height: 84px;line-height: 84px;border-bottom:4px solid #d2d2d2;  overflow: hidden;  margin: 0 10px; position: relative; }
.fxsr li:last-child{    border-bottom: 4px solid #fff;}
.fxsr li input{width: 40%; height: 40px;line-height: 40px;border: 0;font-size: 24px;background: #fff;margin-left: 70px;}
.hqyzm {font-size: 28px;color: #f93f55;float: right;height: 30px;line-height: 28px;margin-top: 28px;padding:0 20px;border-left: 4px solid #d2d2d2;  }
.fxsj{width: 28px;height:44px; display:block;position: absolute;top: 22px;left: 20px;}
.fxsj img{width: 100%;height: auto;display: block}
.fxmm{width: 39px;height:24px; display:block;position: absolute;top: 32px;left:10px;}
.fxmm img{width: 100%;height: auto;display: block}
.fxzcan{overflow: hidden;margin-top: 24px;}
.fxzcan input{height: 80px;line-height: 80px;background: #fff100;color: #f93f55;text-align: center;font-size: 28px;border-radius: 10px;width: 48%;display: block}
.yh{float: left}
.ys{float: right}
.wdbg p{font-size: 26px;color: #fff;text-align: center;display: block;margin-top: 20px;}
.wenz{padding: 50px 30px 60px;margin: 0 30px;border-radius: 10px;background: #fff;}
.wenz .yhjs{font-size: 26px;color: #475c68;text-indent: 50px;line-height: 34px;}
.wenz li{margin-top: 40px;position: relative;}
.wenz li h3{font-size: 26px;color: #475c68;font-weight: bold;text-indent: 30px;}
.wenz li p{font-size: 24px;color: #475c68;line-height:34px;}
.lsfk{position: absolute; display: block; content: ""; width:20px; height:20px; background: #475c68; left:0; top:6px;  -webkit-transform: rotate(135deg);  }
.foot{padding: 0 30px;}
.foot p{font-size: 24px;color: #3e5360;text-align: center;margin: 30px 0;}
.yun{position: absolute;z-index: 3;width: 110px;height: 72px;  top:-13%;right:10px;}
.yun img{width: 100%;height: auto;}




.nurse_box{ font-size: 26px;color: #475c68;font-weight: bold;}
.nurse_box .n_tit{margin-top: 20px;font-size: 30px;}
.nurse_box .n_content{display: flex;margin-top:30px;box-sizing: border-box;box-shadow: 10px 10px 30px #f5f5f5;border-radius: 12px;padding:10px;}

.nurse_box .n_content .n_img{width:180px;height:180px;margin-right: 20px;border-radius: 12px;}
.nurse_box .n_content .n_price{flex-grow: 1;}
.nurse_box .n_content .n_price h3{font-size:32px;margin:10px 0;width:260px; overflow:hidden;white-space:nowrap; /*文字超出宽度则显示ellipsis省略号*/text-overflow:ellipsis;}
.nurse_box .n_content .n_price h4{font-size: 28px;margin-top: 24px;}
.nurse_box .n_content .n_btn{align-self: end;padding:10px 24px;border-radius: 30px;background-color: #fff100;color:#f93f55;border:none;white-space: nowrap;}
/*蒙层*/
.m_box{
  text-align: right;
  width:100%;
  height:100%;
  background-color:#000;
  filter:alpha(opacity=80);
  -moz-opacity:0.8;
  opacity:0.8;
  position:absolute;
  left:0px;
  top:0px;
  z-index:1000;
  display: none;
}
.noScroll{
  overflow: hidden;
}
.topBox{
  text-align: right;
}
</style>
